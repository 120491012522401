.mainWrap :global(.style_headerWrap__oLFPc) {
    background: transparent;
}

.mainbg {
    background-color: #fcfcff;
}

.mainWrap {
    background-image: url("../../../assets/images/MainBG.png");
    background-position: right;
    background-size: cover;
}

.innerMainWrap {
    display: flex;
    max-width: 1600px;
    margin: 2rem auto 0;
    padding: 4rem 2rem;
}

.TextSec {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

.ImgSec {
    flex: 1;
    width: 50%;
}

.Heading {
    font-size: 3.4rem;
    color: #56B6FF;
    font-family: Poppins-ExtraBold, sans-serif;
}

.Text {
    font-family: Poppins-Light, sans-serif;
    font-size: 1.8rem;
    padding: 2rem 0;
}

.mainBtn {
    font-size: 1.5rem;
    font-family: Poppins-Medium, sans-serif;
    color: white !important;
    background-color: #56B6FF;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    display: inline-block;
    margin-top: 1rem;
}

.bottomSec {
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    margin: auto;
}

.btmsec2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-self: flex-end;
}

.btmsec2 img {
    width: 100%;
}

.btmssec2txt {
    text-align: center;
    color: #56B6FF;
    font-size: 1.6rem;
    font-family: Poppins-Medium, sans-serif;
    margin: 0.5rem 0;
}

.btmText {
    align-self: center;
    font-size: 24px;
    font-family: Poppins-MediumItalic, sans-serif;
    color: black;
    padding: 1.5rem 0;
}

@media screen and (max-width: 1800px) {
    .ImgSec img {
        width: 100%;
    }
}

@media screen and (max-width: 1700px) {
    .bottomSec {
        padding: 0 2rem;
    }

    .Text {
        font-size: 1.5rem;
    }

    .mainBtn {
        font-size: 1.2rem;
    }

    .btmText {
        font-size: 1.5rem;
    }

    .btmssec2txt {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1600px) {
    .innerMainWrap {
        max-width: 1170px;
    }

    .Heading {
        font-size: 2.5rem;
    }

    .Text {
        font-size: 1.2rem;
        padding: 1.5rem 0;
    }

    .mainBtn {
        font-size: 1rem;
    }

    .bottomSec {
        max-width: 1170px;
    }

    .btmssec2txt {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .Heading {
        font-size: 2.2rem;
    }

    .Text {
        font-size: 1.2rem;
        padding: 1rem 0;
    }

    .btmssec2txt {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .mainBtn {
        font-size: 1rem;
        padding: 0.7rem 1rem;
    }

    .btmText {
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .innerMainWrap {
        flex-direction: column;
    }

    .ImgSec {
        width: 100%;
        align-self: center;
        margin-top: 2rem;
        text-align: center;
    }

    .ImgSec img {
        max-width: 500px;
        margin: auto;
    }

    .btmsec2 {
        width: 100%;
        max-width: 500px;
        align-self: center;
    }

    .TextSec {
        width: 100%;
    }

    .Text {
        color: white;
    }
}
