.heading {
    font-size: 36px;
    color: #3a3161;
    font-family: Poppins-SemiBold, sans-serif;
    margin-bottom: 2rem;
}

.contactwrap {
    max-width: 1170px;
    margin: auto;
    padding: 6rem 0;
}

.contactwrap label {
    font-size: 16px;
}

.blogs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 0 -15px;
    width: calc(100% + 30px);
}

.blog {
    width: 369px;
    margin: 0 15px 4rem;
}

.blog img {
    width: 100%;
}

.title {
    font-size: 18px;
    font-family: Poppins-SemiBold, sans-serif;
    color: #36324a;
    margin: 1.5rem 0 0 0;
}

.desc {
    font-size: 16px;
    font-family: Poppins-SemiBold, sans-serif;
    color: #36324a;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.imgWrap {
    position: relative;
}

.dateWrap {
    position: absolute;
    background: white;
    color: #36324a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Poppins-SemiBold, sans-serif;
    border-radius: 5px;
    top: 10px;
    left: 10px;
    padding: 10px;
    text-align: center;
    line-height: 14px;
}

@media screen and (max-width: 1600px) {
    .blogswrap {
        max-width: 920px;
    }

    .blogs {
        margin: 0 0 0 -10px;
        width: calc(100% + 20px);
    }

    .blog {
        margin: 0 10px 2rem;
        width: 293px;
    }

    .desc {
        font-size: 16px;
    }
}

@media screen and (max-width: 1200px) {
    .desc {
        font-size: 16px;
    }

    .heading {
        text-align: center;
    }
}

@media screen and (max-width: 970px) {
    .blogswrap {
        margin: 0 2rem;
    }
}

@media screen and (max-width: 767px) {
    .desc {
        font-size: 16px;
    }
}
