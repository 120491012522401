.textImageWrap {
  background-color: #fcfcff;
  padding: 10rem 0;
}
.heading :global(.text-transition) {
  display: inline-block !important;
  color: #b9b9b9;
}
.heading div {
  text-decoration: line-through !important;
}

.txtimgInnerWrap {
  max-width: 1600px;
  margin: auto;
}
.textimg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading {
  font-size: 36px;
  font-family: Poppins-SemiBold, sans-serif;
  color: #333247;
  margin-bottom: 1.5rem;
}

.heading div {
  color: #b9b9b9;
}

.heading span {
  color: #56b6ff;
}
.content {
  font-size: 28px;
  color: #6f6c7f;
  font-family: Poppins-Regular, sans-serif;
}
.content b {
  font-family: Poppins-SemiBold, sans-serif;
}
.text {
  flex: 1;
}
.img {
  flex: 1;
}
.img img {
  max-width: 773px;
  width: 100%;
}
.textWrp {
  padding: 2rem;
  max-width: 700px;
  margin: auto;
}
@media screen and (max-width: 1700px) {
  .heading {
    font-size: 30px;
  }
  .content {
    font-size: 22px;
  }
}
@media screen and (max-width: 1600px) {
  .txtimgInnerWrap {
    max-width: 1170px;
  }
  .heading {
    font-size: 25px;
  }
  .content {
    font-size: 18px;
  }
  .textImageWrap {
    padding: 5rem 0;
  }
}
@media screen and (max-width: 1200px) {
  .heading {
    font-size: 25px;
  }
  .content {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .textimg {
    flex-direction: column;
  }
  .textImageWrap {
    padding: 4rem 0;
  }
  .heading {
    font-size: 22px;
  }
  .content {
    font-size: 16px;
  }
  .textimg:nth-child(2) {
    flex-direction: column-reverse;
  }
}
