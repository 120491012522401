.pricingWrap {
    padding: 2rem 0;
    max-width: 100%;
    margin: auto;
    text-align: center;
}

.heading {
    font-size: 44px;
    font-family: Poppins-SemiBold, sans-serif;
    color: #3a3161;
}

.subheading {
    font-size: 30px;
    font-family: Poppins-Medium, sans-serif;
    color: #56B6FF;
}

.active {
    font-size: 24px;
    font-family: Poppins-Medium, sans-serif;
    color: #333247;
    margin: 0 20px;
}

.notactive {
    font-size: 24px;
    font-family: Poppins-Medium, sans-serif;
    color: #d4d4da;
    margin: 0 20px;
}

.togglewrap {
    margin: 3rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.infoWrap {
    width: 35%;
    padding-left: 0;
    color: #575a73;
    font-family: Poppins-Regular, sans-serif;
    background-color: white;
    margin: 30px;
}

.infoWrap ul {
    list-style: none;
    border: 1px solid #dddde4;
    border-radius: 5px;
    padding-left: 0;
    color: #575a73;
    font-family: Poppins-Regular, sans-serif;
    background-color: white;
    border-bottom: 0;
    margin-bottom: 0;
    font-size: 16px;
}

.infoWrap ul li {
    border-bottom: 1px solid #dddde4;
    padding: 1rem 0;
}

.price {
    font-size: 30px;
    color: #56B6FF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Dsign {
    font-size: 16px;
    color: #3a3161;
    font-family: Poppins-Light, sans-serif;
    line-height: 70px;
}

.button {
    font-size: 2rem !important;
    color: white;
    background: #56B6FF;
    border: 0;
    width: 100%;
    padding: 0.5rem;
    outline: none !important;
}

.buttonPricing {
    font-size: 1.5rem !important;
    color: white;
    background: #56B6FF;
    width: 90%;
    padding: 0.5rem;
    outline: none !important;
    margin: 25px 0;
    border-radius: 0.358rem;
    border: 0 #56B6FF;
}

.buttonPricing:hover {
    box-shadow: 0 8px 25px -8px #56b6ff;
}

.trustedIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.trustedIcons img {
    margin: 0 4rem;
}

.stripe {
    max-width: 170px;
    width: 100%;
}

.guarantee {
    max-width: 130px;
    width: 100%;
}

.mnthfree {
    width: 100%;
    font-family: Poppins-Italic, sans-serif;
    font-size: 20px;
    color: #56B6FF;
    margin: 1rem 0 3rem;
}

.hiddenmnthfree {
    width: 100%;
    font-family: Poppins-Italic, sans-serif;
    font-size: 20px;
    color: #56B6FF;
    margin: 1rem 0 3rem;
    visibility: hidden;
}

@media screen and (max-width: 1600px) {
    .pricingWrap {
        padding: 5rem 2rem;
        max-width: 100%;
    }

    .heading {
        font-size: 30px;
    }

    .subheading {
        font-size: 20px;
    }

    .active {
        font-size: 18px;
    }

    .notactive {
        font-size: 18px;
    }

    .togglewrap {
        margin: 1.5rem 0 0;
    }

    .infoWrap ul li {
        font-size: 16px;
        padding: 0.5rem 0;
    }

    .price {
        font-size: 50px;
    }

    .Dsign {
        line-height: 60px;
    }

    .button {
        font-size: 22px;
        padding: 1rem;
    }

    .mnthfree {
        margin: 1rem 0 1.5rem;
    }

    .hiddenmnthfree {
        margin: 1rem 0 1.5rem;
    }

    .trustedIcons {
        margin-top: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .mnthfree {
        margin: 1rem 0 1.5rem;
        font-size: 16px;
    }

    .hiddenmnthfree {
        margin: 1rem 0 1.5rem;
        font-size: 16px;
    }

    .pricingWrap {
        padding: 4rem 2rem;
    }

    .heading {
        font-size: 25px;
    }

    .subheading {
        font-size: 18px;
    }

    .active {
        font-size: 16px;
    }

    .notactive {
        font-size: 16px;
    }

    .togglewrap {
        margin: 1.5rem 0 0;
    }

    .infoWrap li {
        font-size: 16px;
        padding: 0.5rem 0.5rem !important;
    }

    .price {
        font-size: 40px;
    }

    .Dsign {
        line-height: 50px;
        font-size: 23px;
    }

    .button {
        font-size: 22px;
        padding: 1rem;
    }

    .trustedIcons img {
        margin: 0 2rem;
    }

    .trustedIcons {
        margin-top: 2rem;
    }

    .stripe {
        max-width: 120px;
    }

    .guarantee {
        max-width: 100px;
    }
}
