.faqWrap {
    background-color: #f5f4f8;
    padding: 7rem 0;
}

.innerWrap {
    max-width: 1170px;
    margin: auto;
}

.heading {
    font-size: 44px;
    font-family: Poppins-SemiBold, sans-serif;
    color: #3a3161;
    margin-bottom: 2rem;
}

.que {
    font-size: 24px;
    color: #3a3161;
    font-family: Poppins-SemiBold, sans-serif;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 80px;
}

.AnsWrap {
    overflow: hidden;
}

.Ans {
    font-size: 18px;
    color: #575a73;
    font-family: Poppins-Regular, sans-serif;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 120px;
}

.Qwrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.qoutewrap {
    padding: 3rem 1.5rem;
    background: white;
    border: 1px solid #d3d4dc;
    border-radius: 5px;
    height: 380px;
}

.faqWrap :global(.swiper-container) {
    padding-bottom: 70px;
}

.faqWrap :global(.swiper-slide-active) .qoutewrap {
    border-top: 5px solid #56B6FF;
    box-shadow: 0 10px 12.5px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1600px) {
    .innerWrap {
        max-width: 920px;
    }

    .heading {
        font-size: 25px;
    }

    .que {
        font-size: 18px;
    }

    .Ans {
        font-size: 14px;
    }

    .faqWrap {
        padding: 5rem 0;
    }
}

@media screen and (max-width: 1200px) {
    .innerWrap {
        padding: 0 2rem;
    }

    .heading {
        font-size: 25px;
    }

    .que {
        font-size: 18px;
    }

    .Ans {
        font-size: 14px;
    }

    .Qwrap {
        margin-bottom: 1.5rem;
    }

    .Qwrap img {
        max-width: 20px;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .heading {
        font-size: 25px;
    }

    .faqWrap {
        padding: 4rem 0;
    }
}
