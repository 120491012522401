.headerWrap {
    background: white;
    filter: drop-shadow(0 0 30px rgba(0, 0, 0, 0.1));
}

.MenuItems {
    align-items: center;
}

.header {
    font-size: 2rem;
}

.container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 2rem;
}

.logo {
    max-width: 150px;

}

.MenuItems a {
    font-size: 18px;
    margin-right: 1.8rem;
    color: #061E40;
    font-family: "Poppins-Regular", sans-serif;
}

.MenuItems span {
    margin-right: 1.8rem;
}

.MenuItems span a {
    margin-right: 0;
}

a.headerBtn {
    font-size: 18px;
    background: #061E40;
    border-radius: 5px;
    color: #ffffff;
    padding: 1rem 1.5rem;
    font-family: Poppins-SemiBold, sans-serif;
    margin-right: 0;
}

.menu {
    display: none;
}

.menu svg {
    color: #061E40;
}

@media screen and (max-width: 1700px) {
    .container {
        padding: 1.2rem 2rem;
    }
}

@media screen and (max-width: 1600px) {
    .container {
        max-width: 1170px;
    }

    .logo {
        max-width: 140px;
    }

    .MenuItems a {
        font-size: 14px;
    }

    a.headerBtn {
        font-size: 15px;
        padding: 0.8rem 1rem;
    }
}

@media screen and (max-width: 767px) {
    .MenuItems {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1rem;
    }

    .MenuItems a {
        font-size: 1rem;
        margin-right: 1rem;
    }

    a.headerBtn {
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }

    .menu {
        display: block;
    }

    .menu svg {
        max-width: 30px;
        max-height: 30px;
        cursor: pointer;
    }

    .MenuItems a {
        display: none;
    }

    a.headerBtn {
        display: block;
    }

    .mobileMenu {
        position: absolute;
        background: white;
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 9;
    }

    .mobileMenu a {
        font-family: Poppins-Regular, sans-serif;
        color: #575a73;
        font-size: 14px;
        padding: 1rem 2rem;
        border-bottom: 1px solid #f6f6f7;
    }

    .headerWrap {
        filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.3));
    }
}

@media screen and (max-width: 576px) {
    .logo {
        max-width: 80px;
    }
}