.Heading {
  color: white;
  font-size: 36px;
  font-family: 'Poppins-Medium', sans-serif;
  text-align: center;
  padding-top: 5rem;
}
.TrustedBrandsWrap {
  background-color: #333247;
}
.TrustedBrandsLogo {
  width: 100%;
}
.TextBox {
  background: white;
  max-width: 1170px;
  margin: auto;
  border-radius: 5px;
  padding: 6rem 3rem;
  text-align: center;
  margin-bottom: -110px;
  box-shadow: 6px 2px 21px #00000026;
  position: relative;
}
.TextHeading {
  color: black;
  font-size: 36px;
  font-family: 'Poppins-SemiBold', sans-serif;
  margin-bottom: 3rem;
}

.buttonWrap a {
  font-size: 20px;
  font-family: 'Poppins-SemiBold', sans-serif;
  color: white;
  width: 225px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  background-color: #56B6FF;
  border-radius: 5px;
}
.buttonWrap {
  display: flex;
  margin: 2.5rem 0;
  justify-content: center;
}

@media screen and (max-width: 1600px) {
  .Heading {
    font-size: 25px;
  }
  .buttonWrap a {
    width: 160px;
    height: 50px;
    font-size: 16px;
    margin: 0 5px;
  }
  .buttonWrap {
    margin: 2rem 0;
  }
  .TextBox {
    max-width: 920px;
    padding: 4rem 3rem;
    margin-bottom: -90px;
  }
  .TextHeading {
    font-size: 22px;
  }
}

@media screen and (max-width: 1200px) {
  .Heading {
    margin: 0 2rem;
  }
  .TextBox {
    /* margin: 0rem 2rem; */
    padding: 5rem 2rem;
    margin-bottom: -90px;
  }
  .TextHeading {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .buttonWrap a {
    width: 180px;
    height: 50px;
  }
}
@media screen and (max-width: 970px) {
  .TextBox {
    margin: 0 2rem;
    margin-bottom: -90px;
  }
}

@media screen and (max-width: 767px) {
  .Heading {
    font-size: 20px;
  }
  .TextBox {
    margin: 0 2rem;
    padding: 3rem 1rem;
    margin-bottom: -60px;
  }
  .TextHeading {
    font-size: 22px;
    margin-bottom: 1rem;
  }

  .buttonWrap a {
    width: 120px;
    height: 40px;
    font-size: 0.8rem;
    margin: 0 5px;
  }
  .buttonWrap {
    margin-top: 1.5rem;
  }
  .TextHeading {
    font-size: 18px;
  }
}
