.Deatilwrap {
    max-width: 1170px;
    margin: auto;
    padding: 10rem 0;
}

.blogimg {
    position: relative;
    border: 5px;
    text-align: center;
}

.datewrap {
    position: absolute;
    background: white;
    color: #36324a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Poppins-SemiBold, sans-serif;
    border-radius: 5px;
    top: 0;
    left: 0;
    padding: 10px;
    text-align: center;
    line-height: 14px;
}

.heading {
    color: #36324a;
    font-size: 30px;
    font-family: Poppins-SemiBold, sans-serif;
    padding: 3rem 0;
    word-break: break-word;
}

.text {
    font-size: 16px;
    font-family: Poppins-Regular, sans-serif;
    color: #6f6c7f;
    word-break: break-word;
}

.tagswrap {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    padding-top: 3rem;
    border-top: 1px solid #dfdfe0;
}

.socialMediaWrap svg {
    max-width: 20px;
    max-height: 20px;
    fill: #6f6c7f;
}

.taginnerwrap {
    display: flex;
    align-items: center;
}

.taghd {
    font-size: 20px;
    font-family: Poppins-SemiBold, sans-serif;
    color: #36324a;
    margin-right: 10px;
}

.tags {
    font-size: 16px;
    font-family: Poppins-Regular, sans-serif;
    color: #6f6c7f;
}

.socialMediaWrap {
    width: 150px;
    text-align: right;
}

.socialMediaWrap a {
    margin-left: 15px;
}

.socialMediaWrap svg:hover {
    fill: #f77ade;
}

.blogimg img {
    width: 100%;
    height: 550px;
    object-fit: contain;
}

@media screen and (max-width: 1600px) {
    .Deatilwrap {
        max-width: 920px;
    }

    .heading {
        padding: 2rem 0;
        font-size: 25px;
    }

    .text {
        font-size: 14px;
    }

    .tagswrap {
        margin-top: 3.5rem;
        padding-top: 2rem;
    }
}

@media screen and (max-width: 1200px) {
    .Deatilwrap {
        padding: 8rem 0;
    }

    .taghd {
        font-size: 18px;
    }

    .tags {
        font-size: 16px;
    }

    .socialMediaWrap svg {
        max-width: 18px;
        max-height: 18px;
    }
}

@media screen and (max-width: 970px) {
    .Deatilwrap {
        margin: 0 2rem;
        padding: 8rem 0;
    }
}

@media screen and (max-width: 767px) {
    .Deatilwrap {
        padding: 5rem 0;
    }

    .heading {
        padding: 2rem 0;
        font-size: 20px;
    }

    .text {
        font-size: 14px;
    }

    .tagswrap {
        margin-top: 3rem;
        padding-top: 2rem;
    }

    .taghd {
        font-size: 16px;
    }

    .tags {
        font-size: 14px;
    }

    .socialMediaWrap svg {
        max-width: 16px;
        max-height: 16px;
    }
}
