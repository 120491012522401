  .outerWrap {
  background-color: white;
  padding: 8rem 0 0;
}
.reviewsWrap {
  background-color: white;
  padding: 20rem 0 15rem;
  background-image: url('../../../assets/images/TestimonialBG.png');
  background-repeat: no-repeat;
  background-position: top right;
}
.innerWrap {
  max-width: 1600px;
  margin: auto;
}
.quote {
  display: flex;
  align-items: flex-start;
}
.text {
  color: #534f5a;
  font-size: 30px;
  font-family: Poppins-Light, sans-serif;
}
.name {
  color: #56B6FF;
  font-family: Poppins-Regular, sans-serif;
  font-size: 24px;
  margin-top: 2rem;
}
.quoteWrap {
  max-width: 1250px;
}
.quote img {
  max-width: 200px;
  margin-right: 1rem;
  width: 100%;
}
.arrowsWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.arrowsWrap button img {
  margin: 0 1rem;
  cursor: pointer;
}
@media screen and (max-width: 1700px) {
  .innerWrap {
    padding: 0 2rem;
  }
  .text {
    font-size: 25px;
  }
  .name {
    font-size: 22px;
  }
  .quote img {
    max-width: 160px;
  }
}
@media screen and (max-width: 1700px) {
  .innerWrap {
    max-width: 1170px;
  }
  .outerWrap {
    padding: 5rem 0 0;
  }
  .quote img {
    max-width: 100px;
  }
  .text {
    font-size: 20px;
  }
  .name {
    font-size: 18px;
  }
  .reviewsWrap {
    padding: 20rem 0 10rem;
  }
}
@media screen and (max-width: 1200px) {
  .text {
    font-size: 20px;
  }
  .name {
    font-size: 18px;
  }
  .reviewsWrap {
    padding: 20rem 0 10rem;
  }
  .quote img {
    max-width: 120px;
  }
  .arrowsWrap {
    margin-top: 7rem;
  }
}
@media screen and (max-width: 767px) {
  .reviewsWrap {
    padding: 1rem 0 3rem;
    background: none;
    position: relative;
  }
  .arrowsWrap {
    margin-top: 4rem;
  }
  .quote img {
    max-width: 60px;
  }
  .outerWrap {
    padding: 4rem 0 0;
  }
  .quote {
    /* background: #0000001f; */
    padding: 1rem;
  }
}
