.UsesandFeaturesWrap {
  max-width: 1170px;
  margin: auto;
  padding: 10rem 0;
}
.heading {
  color: #3a3161;
  font-size: 60px;
  font-family: 'Poppins-Bold', sans-serif;
  text-align: center;
}
.trans {
  display: inline-block !important;
}
.wrapp {
  margin-top: 8rem;
}
.subheading {
  font-size: 40px;
  font-family: Poppins-Medium, sans-serif;
  color: #3a3161;
  margin-bottom: 4rem;
}
.featureHeading {
  font-size: 18px;
  font-family: Poppins-SemiBold, sans-serif;
  color: white;
  padding: 3rem 0 2rem;
}
.featureText {
  font-size: 16px;
  font-family: Poppins-Regular, sans-serif;
  color: white;
}
.featureWrap {
  border-top: 5px solid #56B6FF;
  background-color: #56B6FF;
  border-radius: 5px;
  text-align: center;
  padding: 3.5rem 1rem;
  /* width: calc(25% - 30px); */
  width: 270px;
  margin: 15px;
}
.icon svg {
  max-height: 60px;
  max-width: 60px;
  fill: white;
}
.featuresOuterWrap {
  display: flex;
  flex-wrap: wrap;
  margin: -15px 0 0 -15px;
  width: calc(100% + 30px);
  justify-content: center;
}
.featureWrap:hover .featureHeading {
  color: #56B6FF;
}
.featureWrap:hover .featureText {
  color: #56B6FF;
}
.featureWrap:hover {
  background-color: white;
}
.featureWrap:hover .icon svg {
  fill: #56B6FF;
}
@media screen and (max-width: 1600px) {
  .heading {
    font-size: 40px;
  }
  .UsesandFeaturesWrap {
    padding: 7rem 0;
    max-width: 920px;
  }
  .wrapp {
    margin-top: 4rem;
  }
  .subheading {
    margin-bottom: 2rem;
    font-size: 30px;
  }
  .featuresOuterWrap {
    margin: -10px 0 0 -10px;
    width: calc(100% + 20px);
  }
  .featureWrap {
    margin: 10px;
    width: 215px;
    padding: 2.5rem 1rem;
  }
  .icon svg {
    max-height: 50px;
    max-width: 50px;
  }
  .featureHeading {
    padding: 2rem 0 1rem;
    font-size: 16px;
  }
  .featureText {
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .heading {
    font-size: 40px;
  }
  .subheading {
    text-align: center;
  }
}
@media screen and (max-width: 970px) {
  .UsesandFeaturesWrap {
    margin: 0 2rem;
  }
}
@media screen and (max-width: 767px) {
  .heading {
    font-size: 28px;
  }
  .UsesandFeaturesWrap {
    padding: 5rem 0;
  }
  .wrapp {
    margin-top: 4rem;
  }
  .subheading {
    margin-bottom: 2rem;
    font-size: 25px;
  }
}
