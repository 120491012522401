.footerWrap {
    background-color: #f5f3f7;
}

.container {
    max-width: 1170px;
    margin: auto;
    padding-top: 16em;
}

.innerContainer {
    display: flex;
    justify-content: space-between;
}

.container hr {
    color: #d3d4dc;
    margin-top: 5rem;
}

.footerNav {
    display: flex;
    flex-direction: column;
}

.navHeading {
    font-size: 16px;
    font-family: 'Poppins-SemiBold', sans-serif;
    color: #3a3161;
    margin-bottom: 2.5rem;
    padding-top: 1.5rem;
}

.navHeading a {
    font-family: 'Poppins-SemiBold', sans-serif;
    color: #3a3161;
}

.logo img {
    max-width: 190px;
}

.footerNav2 {
    display: flex;
    flex-direction: column;
}

.socialmediicons {
    display: flex;
    flex-direction: row;
}

.footerNav2 a {
    background-color: #d3d4dc;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
}

.footerNav2 a:hover {
    background-color: #56B6FF;
}

.rightReservedText {
    font-family: Poppins-Medium, sans-serif;
    font-size: 15px;
}

.rightReservedText a {
    color: #56B6FF;
}

.termsText {
    font-size: 15px;
    display: flex;
}

.termsText a {
    color: #3a3161;
    margin-left: 20px;
    display: block;
}

.bottomFooter {
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0;
}

@media screen and (max-width: 1600px) {
    .container {
        max-width: 920px;
        padding-top: 14em;
    }

    .logo img {
        max-width: 110px;
    }

    .navHeading {
        font-size: 14px;
    }

    .rightReservedText {
        font-size: 13px;
    }

    .termsText {
        font-size: 13px;
    }

    .bottomFooter {
        padding: 2rem 0;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        padding: 14rem 2rem 0 2rem;
    }
}

@media screen and (max-width: 1000px) {
    .logo {
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;
    }

    .innerContainer {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 767px) {
    .container {
        padding: 10rem 2rem 0 2rem;
    }

    .logo img {
        max-width: 120px;
    }

    .footerNav {
        width: 50%;
        text-align: center;
    }

    .footerNav2 {
        width: 50%;
        text-align: center;
    }

    .socialmediicons {
        justify-content: center;
    }

    .navHeading {
        margin-bottom: 1.5rem;
        padding-top: 0.5rem;
    }
}

@media screen and (max-width: 500px) {
    .footerNav {
        width: 100%;
    }

    .footerNav2 {
        width: 100%;
    }

    .bottomFooter {
        flex-direction: column;
    }

    .termsText {
        justify-content: center;
    }

    .rightReservedText {
        text-align: center;
        margin-bottom: 1rem;
    }

    .termsText a {
        margin: 0 10px !important;
    }
}
