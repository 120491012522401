
.container {
  max-width: 1170px;
  margin: auto;
  padding-block: 5rem;
}
.innerContainer {
  display: flex;
  justify-content: space-between;
}
.container hr {
  color: #d3d4dc;
  margin-top: 5rem;
}

@media screen and (max-width: 1600px) {
  .container {
    max-width: 920px;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    padding-inline: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding-inline: 2rem;
  }
}
