.HIWWrap {
    padding: 7rem 0;
    background-color: white;
}

.innerWrap {
    max-width: 1600px;
    margin: auto;
}

.heading {
    font-size: 48px;
    font-family: Poppins-SemiBold, sans-serif;
    margin-bottom: 3rem;
    text-align: center;
    color: #333247;
}

.HIWContentWrap {
    display: flex;
    justify-content: space-between;
}

.hiwsec {
    flex: 1;
    max-width: 480px;
    margin: 0 1rem;
}

.HIWImg {
    max-width: 450px;
    box-shadow: 0 4px 18px -5px #00000047;
    border-radius: 5px;
    width: 100%;
}

.Contentheading {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #333247;
    font-family: Poppins-SemiBold, sans-serif;
    padding: 2rem 0;
}

.Contentheading img {
    margin-right: 1rem;
    max-width: 60px;
}

.Content {
    font-size: 24px;
    color: #575a73;
    font-family: Poppins-Regular, sans-serif;
}

@media screen and (max-width: 1700px) {
    .innerWrap {
        padding: 0 2rem;
    }

    .Contentheading img {
        max-width: 50px;
    }

    .Contentheading {
        font-size: 25px;
    }

    .Content {
        font-size: 20px;
    }

    .heading {
        font-size: 44px;
    }
}

@media screen and (max-width: 1600px) {
    .HIWWrap {
        padding: 5rem 0;
    }

    .heading {
        font-size: 30px;
    }

    .innerWrap {
        max-width: 1170px;
        margin: auto;
    }

    .Contentheading {
        padding: 1.5rem 0;
        font-size: 20px;
    }

    .Contentheading img {
        max-width: 35px;
    }

    .Content {
        font-size: 16px;
    }
}

@media screen and (max-width: 1300px) {
    .heading {
        margin-bottom: 2rem;
    }

    .HIWContentWrap {
        justify-content: center;
        flex-wrap: wrap;
    }

    .hiwsec {
        margin: 1.5rem 1rem;
    }

    .heading {
        font-size: 30px;
    }

    .Contentheading img {
        max-width: 35px;
    }

    .Contentheading {
        font-size: 20px;
        padding: 2rem 0 1rem;
    }

    .Content {
        font-size: 16px;
    }
}

@media screen and (max-width: 900px) {
    .Contentheading img {
        max-width: 35px;
    }

    .Contentheading {
        font-size: 20px;
        padding: 2rem 0 1rem;
    }

    .Content {
        font-size: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .HIWWrap {
        padding: 4rem 0;
    }

    .HIWContentWrap {
        flex-direction: column;
        align-items: center;
    }

    .heading {
        font-size: 25px;
        margin-bottom: 1rem;
    }
}
